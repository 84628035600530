import React from "react"
import { Link } from "gatsby"
import "./sidebar.css"

import profileImage from "../../images/profile2-small.jpg"

const Bio = ({ author, tagline }) => {
  return (
    <div className="bio-main w-75">
      <Link style={{ textDecoration: "none", color: "#223843" }} to="/">
        <img
          src={profileImage}
          style={{ maxWidth: `100px` }}
          className="profile-img"
          alt={author}
        />
        <h3 className="mt-2 author-bio">{author}</h3>
      </Link>
      <small>{tagline}</small>
    </div>
  )
}

export default Bio
