import React from "react"
import {
  FaLinkedin,
  FaGithubSquare,
  FaDev,
  FaInstagram,
  FaTwitterSquare,
} from "react-icons/fa"
import "./sidebar.css"

const SocialLinks = ({ contacts }) => {
  return (
    <div className="side-social-links float-left mt-3 mb-3">
      <a className="text-secondary p-2" href={contacts.linkedin}>
        <span title="Linked In">
          <FaLinkedin size={26} />
        </span>
      </a>
      <a className="text-secondary p-2" href={contacts.twitter}>
        <span title="Twitter">
          <FaTwitterSquare size={26} />
        </span>
      </a>
      <a className="text-secondary p-2" href={contacts.instagram}>
        <span title="Instagram">
          <FaInstagram size={26} />
        </span>
      </a>
      <a className="text-secondary p-2" href={contacts.github}>
        <span title="GitHub">
          <FaGithubSquare size={26} />
        </span>
      </a>
      <a className="text-secondary p-2" href={contacts.dev}>
        <span title="Dev">
          <FaDev size={26} />
        </span>
      </a>
    </div>
  )
}

export default SocialLinks
